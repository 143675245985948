<template>
  <div class="container-xxl header">
    <h2>{{ $t("emergency.title") }}</h2>
    <p style="font-weight: 700">{{ $t("emergency.teaser") }}</p>
    <div v-for="point in points" :key="point" style="margin-bottom: 40px">
      <h5>{{ $t("emergency.points." + point + ".title") }}</h5>
      <p>
        <span v-html="$t('emergency.points.' + point + '.description')"></span><br />
        <a
          v-if="data[point].emergency && !isOpen()"
          role="button"
          class="btn btn-danger btn-sm"
          href="tel:0800336655"
          style="margin-top: 10px"
        >
          <i class="fas fa-medkit"></i> &nbsp;Ärztefon Zürich anrufen
        </a>

        <a
          v-else-if="!data[point].emergency && !isOpen()"
          role="button"
          class="btn btn-primary btn-sm"
          href="tel:+41444821437"
          style="margin-top: 10px"
        >
          <i class="fas fa-envelope"></i> &nbsp;Mail an Praxis Dr. Forouzan
        </a>

        <a
          v-else
          role="button"
          class="btn btn-primary btn-sm"
          href="tel:+41444821437"
          style="margin-top: 10px"
        >
          <i class="fas fa-phone"></i> &nbsp;Praxis Dr. Forouzan anrufen
        </a>
      </p>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import cats from "@/locales/de.json";
import moment from "moment";

export default {
  name: "Notfall",
  data() {
    return {
      points: Object.keys(cats.emergency.points),
      data: cats.emergency.points,
    };
  },
  components: {
    Footer,
  },
  methods: {
    isOpen() {
      moment.locale("de", { trim: false });
      if (
        moment().isoWeekday() < 6 &&
        moment().format("HH:mm") > "07:30" &&
        moment().format("HH:mm") < "16:00"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.container-xxl {
  margin-bottom: 50px;
}

.header {
  padding-top: 50px;
}
</style>
